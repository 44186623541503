<template>
  <div id="app" class="home has-background-white-ter is-fullheight">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" :opacity="1 * 0.75"></loading>

    <my-navbar v-if="$store.state.showNavigation"></my-navbar>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.4/css/all.css" crossorigin="anonymous" />
    <router-view class="is-fullheight pb:4em" />
    <modal ref="myEmergencyModal" />
    <div class="update-dialog" v-if="showUpdateUI">
      <div class="update-dialog__content">
        A new version is found. Refresh to load it?
      </div>
      <div class="update-dialog__actions">
        <button class="update-dialog__button update-dialog__button--confirm" @click="doUpdateOfApp()">
          Update
        </button>
      </div>
    </div>
    <div class="level is-flex is-justify-content-center" v-if="requestNotifications">
      <button class="button is-primary" @click="triggerRequestNotifications()">ENABLE NOTIFICATIONS</button>
    </div>
    <StudentModal ref="myStudentModal" />
    <StaffModal ref="myStaffModal" />
  </div>
</template>

<script>
import MyNavbar from "@/components/MyNavbar.vue";
import Ably from "ably";
const authUrl = "wsauth";
import firebase from "firebase/compat/app";
import Modal from "@/components/Modal.vue";
import "firebase/compat/auth";
import { connectToWS } from "@/helpers/apicalls";
import Loading from "vue-loading-overlay";
import { routeEndsWith } from "@/helpers/functions"

import StudentModal from "@/components/School/PupilModal";
import StaffModal from "@/components/School/StaffModal";

export default {
  name: "appBase",
  components: {
    MyNavbar,
    Modal,
    Loading,
    StudentModal, StaffModal
  },
  data: function () {
    return {
      showUpdateUI: false,
      isLoading: false,
      requestNotifications: false,
    };
  },
  computed: {
    isStaff() {
      return this.$store?.state?.isStaff;
    },
    isLoadingChecked() {

      if (routeEndsWith(this.$route, "auth")) {
        return false;
      }

      if (!this.$store?.state?.isStaff) {
        return this.isLoading
      }
      return this.isLoading
    }

  },

  created() {
    this.$store.dispatch("updateTime");
    const that = this
    document.addEventListener("visibilitychange", () => {
      console.log("My windows state is", document.hidden);
      if (document.hidden) {
        if (this.$store.state.timerReference) {
          console.log("Stopping timer")
          clearInterval(this.$store.state.timerReference);
          this.$store.state.timerReference = null;
        }
        return;
      }



      that.$store.dispatch("updateTime");
    });
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.$workbox.messageSW({ type: "SKIP_WAITING" }).then(() => {
          location.reload();
        });
      });
    }
  },
  methods: {

    async triggerRequestNotifications() {
      Notification.requestPermission().then(x => {
        console.log(x)
        if (x === "granted") {
          location.reload();
        }
        //this.notificationsSupported = x
      })
    },
    async doUpdateOfApp() {
      this.showUpdateUI = false;
    },
    async initAbly(isStaffv) {
      console.log("Hello", isStaffv);

      if (!isStaffv) {
        if (this.$store.state.realtime) {
          this.$store.state.realtime.close();
          this.$store.state.realtime = null;
        }
        return;
      }

      let token = await firebase.auth()?.currentUser?.getIdToken(false);

      if (!token) {
        console.log("Firebase Token refresh failed....")
        return;
      }

      if (!this.$store.state.realtime && token) {

        const api = this.$api
        this.$store.state.realtime = new Ably.Realtime({
          authCallback: async (tokenParams, callback) => {
            try {
              let tokenRequest = await api.get(authUrl, true, true)

              console.log(tokenRequest)


              callback(null, tokenRequest)
            } catch (error) {
              callback(error, null)
            }
          },

        });

        this.$store.state.realtime.connection.once("connected", () => {
          const { tokenDetails } = this.$store.state.realtime.auth;
          console.log(this.$store.state.realtime);
          console.log(
            "Client successfully connected to Ably using JWT auth",
            tokenDetails
          );
        });

        this.$store.state.channel = this.$store.state.realtime.channels.get(
          this.$store.state.currentDomain
        );

        console.log("Subscribing........");
        const thatVue = this;
        this.$store.state.channel.subscribe((message) => {
          console.log("Received: " + JSON.stringify(message));

          console.log(["xxxxx", message.clientId]);
          console.log([thatVue.$store.state.realtime.auth]);

          if (
            message.clientId != thatVue.$store.state.realtime.auth.clientId &&
            message.data.override
          ) {
            thatVue.$refs.myEmergencyModal.showModal(
              message.data.message + "\n" + message.data.name,
              "Urgent"
            );
          }

          thatVue.$store.commit("addMessage", message);
        });

        this.$store.state.channel.history(
          { direction: "forwards" },
          (err, messagesPage) => {
            messagesPage.items.forEach((item) => {
              console.log({ item })
              item._historical = true;
              thatVue.$store.commit("addMessage", item);
            });
          }
        );
      }
    },
  },
  mounted() {
    if (this.$store?.state.isStaff) {
      this.initAbly(this.$store.state.isStaff);
    }

    if ('Notification' in window && 'serviceWorker' in navigator) {
      console.log("I have Notification capability - requesting permissions", Notification.permission)
      if (!["granted", "denied"].includes(Notification.permission)) {
        this.requestNotifications = true
      }
    }

    console.log("Mounted", this.$router);

  },
  watch: {
    "$store.state.isStaff": function (isStaffv) {
      console.log("Wibbke", this.$store.state);
      this.initAbly(isStaffv);
    },

    "$store.state.wsID": function (newVal) {
      if (!this.$testMode()) {
        connectToWS();
        console.log("New wsID", newVal);
      }
      else {
        console.log("Would have connected to websocket....")
      }
    },
    "$store.state.isLoadingFull": function (newVal) {
      console.log("Is loading", newVal)
      this.isLoading = newVal
    },
    "$store.state.currentPupil": function (newVal) {
      if (newVal) {
        this.$refs.myStudentModal.showModal(newVal);
      }
      else {
        this.$refs.myStudentModal.closeModal()
      }

    },
    "$store.state.currentStaffSelected": function (newVal) {
      if (newVal) {
        this.$refs.myStaffModal.showModal(newVal);
      }
      else {
        this.$refs.myStaffModal.closeModal()
      }

    },



  },
};
</script>

<style>
@import "~bulma/css/bulma.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

.fullwidthLabel {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}


.is-centered {
  text-align: center;
}

.is-small-caps {
  font-variant: small-caps !important;
}

.is-rounded {
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 6px;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.make-div-look-like-bulma-input {
  width: 100%;
  background-color: white;
  min-height: 2.5em;
  display: inline-flex;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  border-style: solid;
  border-color: slategray;
  border-width: thin;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-center-margin {
  margin-top: auto;
  margin-bottom: auto;
}
</style>

<style lang="scss">
div.boxTitle {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  margin: auto;
  text-align: center;
  margin-bottom: 0.75rem;
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;

  h4 {
    font-variant: small-caps !important;
    font-size: 2em;
  }
}

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
